<template>
    <div>
        <Header :seet="number" />
        <el-row type="flex" class="account—settings">
            <el-col :span="3" style="width: 15.3%"></el-col>
            <el-col :span="17">
                <div class="settings_box">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item>账号中心</el-breadcrumb-item>
                        <el-breadcrumb-item>增票资质</el-breadcrumb-item>
                    </el-breadcrumb>
                    <div style="display: flex">
                        <div class="box_left">
                            <LeftHurdle />
                        </div>
                        <div class="box_rights">
                            <div class="box_right_top">增值资质</div>
                            <div class="box_right_cent">
                                <div class="added-box">
                                    <div class="added-top">
                                        <div class="added-text">温馨提示：</div>
                                        <div class="added-text">
                                            (1)新增资质流程：点击"添加增票资质"按钮>填写资质信息>上传加盖公章的有效证件扫描件
                                        </div>
                                        <!-- <div class="added-text">
                                            (2)您填写完资质信息和上传资质证件以后，我们会在一个工作日内完成审核工作。
                                        </div> -->
                                        <div class="added-text">
                                            (2)注意有效增值税专用发票开票资质仅为一个。
                                        </div>
                                        <div class="added-text">
                                            (3)下单资质默认会选择1个增票资质，只可单选。
                                        </div>
                                        <!-- <div class="added-text">
                                            (5)发票常见问题请您查看《增票资质帮助》。
                                        </div> -->
                                    </div>
                                    <div class="added-btn">
                                        <div
                                            class="add-added"
                                            @click="addTicket()"
                                        >
                                            添加增票资质
                                        </div>
                                    </div>
                                    <div class="added-cent">
                                        <el-table
                                            @selection-change="
                                                handleSelectionChange
                                            "
                                            :data="tableData"
                                            border
                                            :header-cell-style="{
                                                background: '#cccccc',
                                                color: '#333',
                                                textAlign: 'center',
                                            }"
                                            :cell-style="{
                                                textAlign: 'center',
                                            }"
                                            style="
                                                width: 100%;
                                                text-align: center;
                                            "
                                        >
                                            <!-- <el-table-column
                                                prop="number"
                                                label="选择下单资质"
                                                width="120"
                                                type="selection"
                                            >
                                            </el-table-column> -->
                                            <el-table-column
                                                prop="id"
                                                label="编号"
                                                width="100"
                                            >
                                            </el-table-column>
                                            <el-table-column
                                                prop="invoiceHeader"
                                                label="发票抬头"
                                                width="160"
                                            >
                                            </el-table-column>
                                            <el-table-column
                                                prop="registeredPhone"
                                                label="注册电话"
                                                width="130"
                                            >
                                            </el-table-column>
                                            <el-table-column
                                                prop="taxpayerNo"
                                                label="纳税人识别号"
                                                width="140"
                                            >
                                            </el-table-column>
                                            <el-table-column
                                                prop="bankName"
                                                label="开户行"
                                                width="140"
                                            >
                                            </el-table-column>
                                            <!-- <el-table-column
                                                prop="state"
                                                label="增票资质状态"
                                                width="150"
                                            >
                                                <template slot-scope="scope">
                                                    {{
                                                        stateName(
                                                            scope.row.state
                                                        )
                                                    }}
                                                </template>
                                            </el-table-column> -->
                                            <el-table-column
                                                label="操作"
                                                width="70"
                                                class-name="operation"
                                            >
                                                <template slot-scope="scope">
                                                    <el-button
                                                        size="mini"
                                                        type="text"
                                                        v-if="
                                                            scope.row.state !==
                                                            1
                                                        "
                                                        @click="
                                                            handleEdit(
                                                                scope.$index,
                                                                scope.row
                                                            )
                                                        "
                                                        >修改</el-button
                                                    >
                                                    <el-button
                                                        size="mini"
                                                        type="text"
                                                        @click="
                                                            handleView(
                                                                scope.$index,
                                                                scope.row
                                                            )
                                                        "
                                                        >查看</el-button
                                                    >
                                                    <el-button
                                                        size="mini"
                                                        type="text"
                                                        v-if="
                                                            scope.row.state !==
                                                            1
                                                        "
                                                        @click="
                                                            handleDelete(
                                                                scope.$index,
                                                                scope.row
                                                            )
                                                        "
                                                        >删除</el-button
                                                    >
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="box_right_bom"
                                style="
                                    display: flex;
                                    justify-content: flex-end;
                                    align-items: center;
                                    padding-top: 10px;
                                "
                            >
                                <div class="block">
                                    <el-pagination
                                        @size-change="handleSizeChange"
                                        @current-change="handleCurrentChange"
                                        :current-page="pageNum"
                                        :page-sizes="[4]"
                                        layout="total, sizes, prev, pager, next, jumper"
                                        :total="total"
                                    >
                                    </el-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="3" style="width: 15.5%"></el-col>
        </el-row>
        <Footer />
    </div>
</template>

<script>
import Header from "@/components/Layout/header.vue";
import Footer from "@/components/Layout/footer.vue";
import LeftHurdle from "@/components/Layout/leftHurdle.vue";
import { queryListAndPage, deleteInvoiceById } from "@/api/invoice/invoice";
export default {
    components: { Header, Footer, LeftHurdle },
    data() {
        return {
            number: "4",
            ruleForm: {
                name: "",
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入收货人",
                        trigger: "blur",
                    },
                ],
            },
            tableData: [],
            multipleSelection: [],

            pageNum: 1,
            pageSize: 4,
            total:0,
            pages:0,
        };
    },
    computed: {
        stateName() {
            return (state) => {
                state = "";
                switch (state) {
                    case 0:
                        return "未提交";
                    case 1:
                        return "审批中";
                    case 2:
                        return "审核通过";
                    case 3:
                        return "审核驳回";
                    default:
                        return "通过";
                }
            };
        },
    },
    mounted() {
        this.queryListAndPage();
    },
    methods: {
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        queryListAndPage(pageNum) {
            if(!pageNum){
                pageNum = this.pageNum
            }
            let str = `pageNum=${pageNum}&pageSize=${this.pageSize}`;
            queryListAndPage(str).then((res) => {
                if (res.code == "00000") {
                    this.tableData = res.data.records;
                    this.total = res.data.total
                    this.pages = res.data.pages
                } else {
                    this.$message.error(res.desc);
                }
            });
        },
        //修改
        handleEdit(index, row) {
            this.$router.push({
                name: "AddTicket",
                query: { id: row.id, type: "edit" },
            });
            console.log(index, row);
        },
        //查看
        handleView(index, row) {
            this.$router.push({
                name: "AddTicket",
                query: { id: row.id, type: "view" },
            });
            console.log(index, row);
        },
        //删除
        handleDelete(index, row) {
            this.$confirm("是否删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                center: true,
            })
                .then(() => {
                    deleteInvoiceById(row.id).then((res) => {
                        if (res.code == "00000") {
                            this.queryListAndPage();
                            this.$message({
                                message: res.desc,
                                type: "success",
                            });
                        } else {
                            this.$message.error(res.desc);
                        }
                    });
                })
                .catch(() => {
                    // this.$message({
                    //     type: "info",
                    //     message: "已取消删除",
                    // });
                });

            console.log(index, row);
        },
        addTicket() {
            this.$router.push({ name: "AddTicket" });
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.queryListAndPage(val)
            console.log(`当前页: ${val}`);
        },
    },
};
</script>

<style lang="scss">
.operation .cell {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .el-button {
        margin-left: 0 !important;
    }
}
.settings_box {
    margin: 30px 0;
    .el-breadcrumb {
        font-size: 18px;
        padding-bottom: 20px;
    }
    .box_left {
        margin-right: 20px;
    }
    .box_rights {
        width: 87%;
        border: 1px solid #d7d7d7;
        border-radius: 10px;
        .box_right_top {
            height: 40px;
            line-height: 40px;
            padding-left: 40px;
            background: #d7d7d7;
            border-bottom: 1px solid #d7d7d7;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            font-weight: bold;
        }
        .added-box {
            .added-top {
                padding: 20px;
                font-size: 15px;
                .added-text {
                    margin-bottom: 8px;
                }
            }
            .added-btn {
                margin: 0 0 20px 20px;
                .add-added {
                    width: 120px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    color: #fff;
                    background: #cd9f49;
                    font-size: 15px;
                }
            }
        }
        .added-cent {
            margin-bottom: 30px;
            .el-table__header{
                width: 100% !important;
            }
            .el-table__body{
                width: 100% !important;
            }
            .el-button--text{
                color: #cd9f49;
            }
        }
        .box_right_bom {
            height: 40px;
            line-height: 40px;
            padding-left: 40px;
            background: #d7d7d7;
            border-bottom: 1px solid #d7d7d7;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
}
</style>