var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header',{attrs:{"seet":_vm.number}}),_c('el-row',{staticClass:"account—settings",attrs:{"type":"flex"}},[_c('el-col',{staticStyle:{"width":"15.3%"},attrs:{"span":3}}),_c('el-col',{attrs:{"span":17}},[_c('div',{staticClass:"settings_box"},[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',[_vm._v("账号中心")]),_c('el-breadcrumb-item',[_vm._v("增票资质")])],1),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"box_left"},[_c('LeftHurdle')],1),_c('div',{staticClass:"box_rights"},[_c('div',{staticClass:"box_right_top"},[_vm._v("增值资质")]),_c('div',{staticClass:"box_right_cent"},[_c('div',{staticClass:"added-box"},[_c('div',{staticClass:"added-top"},[_c('div',{staticClass:"added-text"},[_vm._v("温馨提示：")]),_c('div',{staticClass:"added-text"},[_vm._v(" (1)新增资质流程：点击\"添加增票资质\"按钮>填写资质信息>上传加盖公章的有效证件扫描件 ")]),_c('div',{staticClass:"added-text"},[_vm._v(" (2)注意有效增值税专用发票开票资质仅为一个。 ")]),_c('div',{staticClass:"added-text"},[_vm._v(" (3)下单资质默认会选择1个增票资质，只可单选。 ")])]),_c('div',{staticClass:"added-btn"},[_c('div',{staticClass:"add-added",on:{"click":function($event){return _vm.addTicket()}}},[_vm._v(" 添加增票资质 ")])]),_c('div',{staticClass:"added-cent"},[_c('el-table',{staticStyle:{"width":"100%","text-align":"center"},attrs:{"data":_vm.tableData,"border":"","header-cell-style":{
                                            background: '#cccccc',
                                            color: '#333',
                                            textAlign: 'center',
                                        },"cell-style":{
                                            textAlign: 'center',
                                        }},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"prop":"id","label":"编号","width":"100"}}),_c('el-table-column',{attrs:{"prop":"invoiceHeader","label":"发票抬头","width":"160"}}),_c('el-table-column',{attrs:{"prop":"registeredPhone","label":"注册电话","width":"130"}}),_c('el-table-column',{attrs:{"prop":"taxpayerNo","label":"纳税人识别号","width":"140"}}),_c('el-table-column',{attrs:{"prop":"bankName","label":"开户行","width":"140"}}),_c('el-table-column',{attrs:{"label":"操作","width":"70","class-name":"operation"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                                                        scope.row.state !==
                                                        1
                                                    )?_c('el-button',{attrs:{"size":"mini","type":"text"},on:{"click":function($event){return _vm.handleEdit(
                                                            scope.$index,
                                                            scope.row
                                                        )}}},[_vm._v("修改")]):_vm._e(),_c('el-button',{attrs:{"size":"mini","type":"text"},on:{"click":function($event){return _vm.handleView(
                                                            scope.$index,
                                                            scope.row
                                                        )}}},[_vm._v("查看")]),(
                                                        scope.row.state !==
                                                        1
                                                    )?_c('el-button',{attrs:{"size":"mini","type":"text"},on:{"click":function($event){return _vm.handleDelete(
                                                            scope.$index,
                                                            scope.row
                                                        )}}},[_vm._v("删除")]):_vm._e()]}}])})],1)],1)])]),_c('div',{staticClass:"box_right_bom",staticStyle:{"display":"flex","justify-content":"flex-end","align-items":"center","padding-top":"10px"}},[_c('div',{staticClass:"block"},[_c('el-pagination',{attrs:{"current-page":_vm.pageNum,"page-sizes":[4],"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)])])])],1)]),_c('el-col',{staticStyle:{"width":"15.5%"},attrs:{"span":3}})],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }